import "./style.css"
import { Carousel, ConfigProvider } from "antd";
import { LoginBanner } from "../../../assets/images";

const CustomCarousel = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Carousel: {
            dotGap: 2.5,
            dotActiveWidth: 12,
            dotWidth: 12,
            dotHeight: 12,
            dotOffset: 80,
          },
        },
      }}
    >
      <Carousel
      className="carousel"
        autoplay
        dots={{ className: "dots" }}
        speed={1500}
        autoplaySpeed={3000}
      >
        <div className="carouselDiv">
          <img
            className="logiCarouselImage"
            alt="Login Banner"
            src={LoginBanner}
          />
        </div>
        <div className="carouselDiv">
          <img
            className="logiCarouselImage"
            alt="Login Banner"
            src={LoginBanner}
          />
        </div>
        <div className="carouselDiv">
          <img
            className="logiCarouselImage"
            alt="Login Banner"
            src={LoginBanner}
          />
        </div>
      </Carousel>
    </ConfigProvider>
  );
};

export default CustomCarousel;
