import "./style.css";
import { GoogleIconProps } from "../../../constants/interfaces";

const GoogleIcons = (props: GoogleIconProps) => {
  const { className, iconName, style,onClick,disabled } = props;

  return (
    <span className={`material-symbols-outlined iconStyle ${className}`} onClick={onClick} style={{ cursor: !disabled ? "pointer" :"not-allowed"}}>
      {iconName}
    </span>
  );
};

export default GoogleIcons;
