import { Footer } from "antd/es/layout/layout";
import "./style.css";
import { Col, Row } from "antd";
import CustomeButton from "../button";
import GoogleIcons from "../googleIcon";

const FormFooter = (props: any) => {
  const { submitButtonText, onSubmit, onCancel, onReset, resetDisabled } =
    props;
  return (
    <Footer className="formFooter">
      <Row className="fomFooterRow">
        <Col>
          <CustomeButton
            text="Reset"
            className="formFooterResetButton"
            onClick={onReset}
            disabled={resetDisabled}
            icon={<GoogleIcons iconName="refresh" className="refreshIcon" />}
          />
        </Col>
        <Col>
          <CustomeButton text="Cancel" onClick={onCancel} />
          <CustomeButton
            className="fromFooterSubmitButton"
            type="primary"
            text={submitButtonText}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </Footer>
  );
};

export default FormFooter;
