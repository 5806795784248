import { Card, Row, Dropdown, Col, Switch } from "antd";
import GoogleIcons from "../../../common/googleIcon";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../../routes/config";

export const RoleCard = (props: any) => {
  const navigate = useNavigate();
  const { items, defaultRole } = props;
  return (
    <>
      <Card className="roleTypeCard">
        <Row className="roleName">
          <span className="role" onClick={() => navigate(PageRoutes.ViewRole)}>Platform Admin</span>
          <Dropdown
            menu={{ items }}
            overlayClassName="actionDropdown"
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            trigger={["click"]}
            disabled={defaultRole}
          >
            <GoogleIcons
              iconName="more_horiz"
              className="actionBtn"
              disabled={defaultRole}
            />
          </Dropdown>

          <Col span={24} className="roleDesc">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deserunt
            neque quia eos aut. Quisquam officia illum voluptatem quis Lorem
            ipsum, dolor sit amet consectetur adipisicing elit. quis
          </Col>
        </Row>
        <Row className="roleName">
          <span className="roleType">
            <span
              className="roleTypeDot"
              style={{ background: defaultRole ? "#DEDEE1" : "#006CD0" }}
            ></span>
            {defaultRole ? "Default Role" : "Custom Role"}
          </span>
          <span>
            <span
              style={{
                color: defaultRole ? "#DEDEE1" : "#4D4D4F",
                fontSize: "12px",
              }}
            >
              Active
            </span>
            <Switch
              checked={true}
              className="statusSwitch"
              size="small"
              style={{
                backgroundColor: defaultRole ? "#DEDEE1" : "#006CD0",
                cursor: defaultRole ? "not-allowed" : "pointer",
                pointerEvents: !defaultRole ? "none" : "auto",
              }}
              // onChange={(checked) => handleSwitchChange(record.key, checked)}
            />
          </span>
        </Row>
      </Card>
    </>
  );
};
