import Sider from "antd/es/layout/Sider";
import "./style.css";
import { Logo } from "../../../assets/icons";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { PageRoutes } from "../../../routes/config";
import { useEffect, useState } from "react";
import GoogleIcons from "../googleIcon";
 
export const Sidebar = () => {
  const location = useLocation();
  var currentRoute = location?.pathname;
  let count = 21;
  const [selectedKey, setSelectedKey] = useState<any>("");
 
  const menuItems: any = [
    {
      key: "menu",
      label: "Main menu",
      type: "group",
      children: [
        {
          key: "1",
          icon: <GoogleIcons iconName="dashboard" />,
          label: <Link to={PageRoutes.Dashboard}>Dashboard</Link>,
          route: PageRoutes.Dashboard,
        },
        {
          key: "2",
          icon: <GoogleIcons iconName="group" />,
          label: <Link to={PageRoutes.ManageUser}>Manage Users</Link>,
          route: PageRoutes.ManageUser,
        },
        {
          key: "3",
          icon: <GoogleIcons iconName="receipt_long" />,
          label: <Link to={PageRoutes.Tenants}>Tenants</Link>,
          route: PageRoutes.Tenants,
        },
        {
          key: "4",
          icon: <GoogleIcons iconName="key" />,
          label: <Link to={PageRoutes.Configurations}>Configurations</Link>,
          route: PageRoutes.Configurations,
        },
        {
          key: "5",
          icon: <GoogleIcons iconName="admin_panel_settings" />,
          label: <Link to={PageRoutes.Roles}>Roles & Permissions</Link>,
          route: PageRoutes.Roles,
        },
      ],
    },
    {
      key: "others",
      label: "Others",
      type: "group",
      children: [
        {
          key: "6",
          label: (
            <Link to={PageRoutes.ManageUser}>
              <>
                <span> Notification</span>
                <span className="notificationCount">{count}</span>
              </>
              ,
            </Link>
          ),
          icon: <GoogleIcons iconName="notifications" />,
          route: PageRoutes.Notification,
        },
        {
          key: "7",
          icon: <GoogleIcons iconName="settings" />,
          label: <Link to={PageRoutes.ManageUser}>Settings</Link>,
          route: PageRoutes.Settings,
        },
      ],
    },
  ];
 
  const mappedMenuItems = menuItems.flatMap((group: any) =>
    group.children.map((item: any) => ({
      key: item.key,
      label: item.label,
      icon: item.icon,
      route: item.route,
    }))
  );
 
  useEffect(() => {
    var selectedRoute = mappedMenuItems.find((item: any) => {
      return currentRoute === item?.route;
    });
    setSelectedKey(selectedRoute?.key);
  }, [currentRoute, location]);
 
  return (
    <Sider className="sider">
      <div className="logoContainer">
        <img src={Logo} alt={Logo} className="logo" />
      </div>
      <Menu
        mode="vertical"
        className="sidebarMenu"
        items={menuItems}
        selectedKeys={[selectedKey]}
      />
      <div className="terms">Terms & Conditions</div>
    </Sider>
  );
};
 
 