import "./style.css";
import GoogleIcons from "../../../common/googleIcon";
import { useEffect, useRef, useState } from "react";
import CommonModal from "../../../common/modal";
import UserForm from "../../../stateless/users/form";

const isFormDataChanged = (initialData: any, currentData: any) => {
  return JSON.stringify(initialData) !== JSON.stringify(currentData);
};

const AddUser = (props: any) => {
  const { open, setOpen, isEdit, handleRefetch, data } = props;
  const initialFormData = {
    imageURL: null,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    userType: "",
    email: "",
    mobileNumber: "",
    numCode: "+91",
    communicationMethod: "E-mail",
    onBoardingMode: "E-mail",
    allowMultipleSessions: "No",
    twoFactorAuthentication: "No",
  };
  const [formData, setFormData] = useState(initialFormData);

  const formRef = useRef<any>(null);

  useEffect(() => {
    if (isEdit && data && open) {
      const [code, number] = data.mobileNumber.split(" ");
      const parsedNumCode = code || "+91";
      const parsedMobileNumber = number || data.mobileNumber;
      const [parsedTitle, ...nameParts] = data.name.split(" ");
      const parsedFirstName = nameParts.shift() || "";
      const parsedLastName = nameParts.pop() || "";
      const parsedMiddleName = nameParts.join(" ") || "";

      setFormData({
        ...data,
        title: parsedTitle || "",
        firstName: parsedFirstName || "",
        middleName: parsedMiddleName || "",
        lastName: parsedLastName || "",
        mobileNumber: parsedMobileNumber,
        numCode: parsedNumCode,
      });
    } else if (open && !isEdit) {
      setFormData(initialFormData);
    }
  }, [isEdit, data, open]);

  const handleFormChange = (changedValues: any) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    handleRefresh();
  };

  const handleRefresh = () => {
    setFormData(initialFormData);
    if (formRef.current) {
      formRef.current.resetFields();
    }
  };

  const handleSubmit = () => {
    handleRefetch(formData);
  };

  const isRefreshButtonDisabled = !isFormDataChanged(initialFormData, formData);

  return (
    <CommonModal
      title="Create new user"
      open={open}
      handleClose={handleClose}
      primaryButtonText={isEdit ? "Update User" : "Create User"}
      primaryClickHandler={handleSubmit}
      secondaryClickHandler={handleRefresh}
      secondaryButtonText="Refresh"
      secondaryButtonIcon={
        <GoogleIcons iconName="refresh" className="refreshIcon" />
      }
      secondaryButtonDisabled={isRefreshButtonDisabled}
    >
      <UserForm
        formData={formData}
        onFormChange={handleFormChange}
        onSubmit={handleSubmit}
        formRef={formRef}
      />
    </CommonModal>
  );
};

export default AddUser;
