import { Typography, Form, Col, Row } from "antd";
import { useState } from "react";
import "./style.css";
import CustomCarousel from "../../../common/carousell";
import CommonInput from "../../../common/input";
import { RegExpression } from "../../../../constants/regExp";
import GoogleIcons from "../../../common/googleIcon";
import CustomeButton from "../../../common/button";
import { Logo } from "../../../../assets/icons";
import { PageRoutes } from "../../../../routes/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginState } from "../../../../redux/slices/auth";

const { useForm } = Form;

const LoginPage = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({ email: "", password: "" });
  };

  const onSubmit = () => {
    const { email, password } = formData;

    if (
      email === "admin@hyperdash.com" &&
      password === "Admin@321"
    ) {
      dispatch(setLoginState(true));
      navigate(PageRoutes.ManageUser);
    } else {
      setErrors({
        email:
          email !== "admin@hyperdash.com" ? "Invalid email" : "",
        password: password !== "Admin@321" ? "Incorrect password" : "",
      });
    }
  };

  return (
    <Row className="loginMainRow">
      <Col xl={12} className="loginImageCol">
        <CustomCarousel />
      </Col>
      <Col xs={24} sm={22} md={20} lg={18} xl={12} className="loginFormCol">
        <Row className="loginFormRow">
          <Col xs={22} sm={18} md={16} lg={14} xl={14}>
            <img alt="Logo" className="logoImage" src={Logo} />
          </Col>
          <Col xs={22} sm={18} md={16} lg={14} xl={14}>
            <Typography.Title level={1} className="loginHeadingText">
              Welcome!
            </Typography.Title>
            <Typography.Text className="loginSubHeadingText">
              Please, Sign In to continue to Admin Module
            </Typography.Text>

            <Form
              form={form}
              onFinish={onSubmit}
              className="loginFormForm"
              layout="vertical"
            >
              <CommonInput
                help={errors.email}
                validateStatus={errors.email ? "error" : ""}
                name="email"
                label="Email/Mobile Number"
                rules={[
                  {
                    pattern: RegExpression?.Email,
                    message: "Please enter a valid email address!",
                  },
                ]}
                type="text"
                value={formData.email}
                onChange={handleChange}
                prefix={
                  <GoogleIcons iconName="person" className="prefixIcon" />
                }
                placeholder="Enter Email ID or Mobile Number"
              />
              <CommonInput
                help={errors.password}
                validateStatus={errors.password ? "error" : ""}
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                prefix={<GoogleIcons iconName="lock" className="prefixIcon" />}
                placeholder="Enter Password"
              />
              <Row justify="space-between">
                <Col className="forgotPasswordCol">
                  <Typography.Link
                    className="forgotPasswordText"
                    onClick={() =>
                      navigate(PageRoutes.ForgotPassword, {
                        state: { email: formData.email },
                      })
                    }
                  >
                    Forgot Password?
                  </Typography.Link>
                </Col>
                <CustomeButton
                  type="primary"
                  text="Continue"
                  htmlType="submit"
                  size="large"
                  disabled={!formData.email || !formData.password}
                  icon={<GoogleIcons iconName="arrow_right_alt" />}
                  iconPosition="end"
                />
              </Row>
            </Form>
          </Col>
          <Col span={24} className="termConditionCol">
            <span className="termConditionSpan">Terms & Conditions</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LoginPage;
