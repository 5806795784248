import { Breadcrumb, Col, Layout, Row } from "antd";
import { Sidebar } from "../common/sidebar";
import Header from "../common/header";
import { Outlet } from "react-router-dom";
import "./style.css";
import GoogleIcons from "../common/googleIcon";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";

export const AppLayout = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: "Home",
    },
  ]);

  return (
    <Row style={{ height: "100vh" }}>
      <Layout>
        <Col className="sidebar">
          <Sidebar />
        </Col>
        <Col className="headers">
          <Header />
          <Content>
            <Row className="subHeaderContentRow">
              <Breadcrumb
                className="breadcrumb"
                separator={<GoogleIcons iconName="chevron_right" />}
                items={breadcrumbItems}
              />
            </Row>
            <Outlet context={{ setBreadcrumbItems }} />
          </Content>
        </Col>
      </Layout>
    </Row>
  );
};
