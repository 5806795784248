import { Route, Routes } from "react-router-dom";
import LoginPage from "../components/containers/auth/loginPage";
import { ChangePassword } from "../components/containers/auth/changePassword";
import { PageRoutes } from "./config";
import ManageUser from "../components/containers/users/list";
import { AppLayout } from "../components/layout";
import ForgotPassword from "../components/containers/auth/forgotPassword";
import { ProtectedRoute, PublicRoute } from "./protectedRoutes";
import Tenants from "../components/containers/tenants";
import { useSelector } from "react-redux";
import AddRole from "../components/containers/roles/addRoles";
import EditRole from "../components/containers/roles/editRoles";
import ViewRole from "../components/containers/roles/viewRole";
import Roles from "../components/containers/roles/list";

const RoutesOption = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.login.isLoggedIn);

  return (
    <Routes>
      <Route element={<PublicRoute isLoggedIn={isLoggedIn} />}>
        <Route path={PageRoutes.Login} element={<LoginPage />} />
        <Route path={PageRoutes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={PageRoutes.ChangePassword} element={<ChangePassword />} />
      </Route>
      <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
        <Route path="/" element={<AppLayout />}>
          <Route path={PageRoutes.ManageUser} element={<ManageUser />} />
          <Route path={PageRoutes.Tenants} element={<Tenants />} />
          <Route path={PageRoutes.AddRole} element={<AddRole />} />
          <Route path={PageRoutes.EditRole} element={<EditRole />} />
          <Route path={PageRoutes.ViewRole} element={<ViewRole />} />
          <Route path={PageRoutes.Roles} element={<Roles />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RoutesOption;
