import { Card, Col, MenuProps, Row } from "antd";
import CustomeButton from "../../../common/button";
import Filter from "../../../common/filter";
import GoogleIcons from "../../../common/googleIcon";
import SearchField from "../../../common/searchField";
import { useEffect, useState } from "react";
import "./style.css";
import "../../users/list/style.css";
import ConfirmationModal from "../../../common/confirmModal";
import { RoleCard } from "../../../stateless/roles/card";
import { Link, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../../routes/config";
import { useOutletContext } from "react-router-dom";

const Roles = () => {
  const { setBreadcrumbItems }: any = useOutletContext();
  useEffect(() => {
    setBreadcrumbItems([
      { title: "Home" },
      { title: <Link to={PageRoutes.Roles}>Role</Link> },
    ]);
  }, [setBreadcrumbItems]);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const defaultRole = false;
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="actionItems"
          onClick={() => navigate(PageRoutes.EditRole)}
        >
          <GoogleIcons iconName="edit" className="actionItemIcon" />
          <span>Edit Details </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="actionItems" onClick={() => setDeleteOpen(true)}>
          <GoogleIcons iconName="delete" className="actionItemIcon" />
          <span>Delete</span>
        </div>
      ),
    },
  ];

  const userItems: any = [
    {
      key: "1",
      label: "Platform Super Admin",
      value: "Platform Super Admin",
    },
    {
      key: "2",
      label: "Platform System Admin",
      value: "Platform System Admin",
    },
    {
      key: "3",
      label: "Platform Support Staff",
      value: "Platform Support Staff",
    },
  ];

  const handleAdd = () => {
    navigate(PageRoutes.AddRole);
  };

  return (
    <>
      <Card className="userCard" id="userCard">
        <Row gutter={[16, 24]} className="buttonRow">
          <Col span={8}>
            <SearchField placeholder={"Search name or email"} />
          </Col>
          <Col span={4}>
            <Filter
              text={"Role Type"}
              items={userItems}
              name={"roleType"}
              //   data={rowData}
              //   checkedList={userTypeFilter}
              //   setCheckedList={setUserTypeFilter}
            />
          </Col>

          <Col span={5} className="createUserBtn">
            <CustomeButton
              type="primary"
              text="Create New Role"
              size="large"
              icon={<GoogleIcons iconName="add" />}
              iconPosition="end"
              className="userbtn"
              onClick={() => handleAdd()}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="cardRow">
          <Col span={8}>
            <RoleCard items={items} defaultRole={defaultRole} />
          </Col>
        </Row>
      </Card>
      <ConfirmationModal
        open={deleteOpen}
        title={"Delete User !"}
        setOpen={setDeleteOpen}
        cancelText="No"
        okText="Yes"
        message="You are going to delete this user Are you sure"
        okButtonDanger={true}
      />
    </>
  );
};

export default Roles;
