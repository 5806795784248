import { Card, Col, Row } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import SearchField from "../../../common/searchField";
import PermissionTable from "../../../stateless/rolesAndPermissions/permissionTable";
import { Link, useOutletContext } from "react-router-dom";
import { PageRoutes } from "../../../../routes/config";

const ViewRole = () => {
  const { setBreadcrumbItems }: any = useOutletContext();
  useEffect(() => {
    setBreadcrumbItems([
      { title: "Home" },
      { title: <Link to={PageRoutes.Roles}>Role</Link> },
      { title: "Platform Admin" },
    ]);
  }, [setBreadcrumbItems]);

  const [search, setSearch] = useState("");

  const initialFormData = {
    role: "",
    description: "",
    tableData: [
      {
        key: "1",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: false, edit: true },
      },
      {
        key: "2",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: true, edit: false },
      },
      {
        key: "3",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: true, edit: false },
      },
    ],
  };
  const getFilteredTableData = () => {
    if (!search) return initialFormData?.tableData;

    return initialFormData?.tableData.filter(
      (item: any) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <>
      <Row className="viewRolesMainRow">
        <Card bordered={false} className="viewRolesCard" id="roleCard">
          <Row className="viewRolesFirstRow" id="rolesFirstRow">
            <Col
              xs={24}
              sm={12}
              md={10}
              lg={8}
              xl={6}
              className="roleFormLeftCol"
            >
              <span className="viewRolesLabel">Role Name</span>
              <span className="viewRolesText">Platform Admin</span>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={14}
              lg={16}
              xl={18}
              className="roleFormRightCol"
            >
              <span className="viewRolesLabel">Description</span>
              <span className="viewRolesText">
                Lorem ipsum dolor sit amet consectetur. Velit libero enim
                consectetur aliquam. Commodo eget quam tincidunt imperdiet
                ullamcorper fermentum ultricies.
              </span>
            </Col>
          </Row>
          <Row className="roleFormSecRow" id="rolesSecRow">
            <Col
              xs={24}
              sm={12}
              md={13}
              lg={15}
              xl={17}
              className="roleFormTextCol"
            >
              <span className="roleFormHeadText">Permissions</span>
              <span className="roleFormSubHeadText">
                Lorem ipsum dolor sit amet consectetur. Velit libero enim
                consectetur aliquam.
              </span>
            </Col>
            <Col xs={24} sm={12} md={11} lg={9} xl={7}>
              <SearchField
                placeholder="Search Permissions"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
            </Col>
          </Row>
          <Row className="roleFromTableRow">
            <PermissionTable rowData={getFilteredTableData()} checkboxDisaled />
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default ViewRole;
