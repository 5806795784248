import {
  Checkbox,
  Col,
  ConfigProvider,
  Dropdown,
  MenuProps,
  Row,
  Tooltip,
} from "antd";
import CustomeButton from "../button";
import GoogleIcons from "../googleIcon";
import { useEffect, useRef, useState } from "react";
import "./style.css";
const Filter = (props: any) => {
  const { text, items,checkedList, setCheckedList } =
    props;
  const CheckboxGroup = Checkbox.Group;
  const [open, setOpen] = useState(false);


  const options = (
    <>
      <div className="filterDropdown">
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedList(items?.map((item: any) => item.value));
            } else {
              setCheckedList([]);
            }
          }}
          checked={checkedList?.length == items?.length}
        >
          All
        </Checkbox>
        <CheckboxGroup
          options={items}
          value={checkedList}
          onChange={(value: any) => setCheckedList(value)}
        />
      </div>
      <Row gutter={10}>
        <Col span={12}>
          <CustomeButton
            type="text"
            text="Clear All"
            size="small"
            className="filterChildBtn"
            style={{ fontSize: "13px" }}
            onClick={() => {
              setCheckedList([]);
              setOpen(!open);
            }}
          />
        </Col>
        <Col span={12}>
          <CustomeButton
            type="text"
            text="OK"
            size="small"
            className="filterChildBtn"
            style={{ fontSize: "13px" }}
            onClick={() => {
              setOpen(!open);
              // handleSetFilter();
            }}
          />
        </Col>
      </Row>
    </>
  );
  return (
    <>
      <Tooltip
        placement="bottomRight"
        title={options}
        trigger="click"
        // onOpenChange={}
        open={open}
        className="tooltip"
      >
        <CustomeButton
          icon={
            !open ? (
              <GoogleIcons iconName="keyboard_arrow_down" />
            ) : (
              <GoogleIcons iconName="keyboard_arrow_up" />
            )
          }
          iconPosition="end"
          type="default"
          text={
            checkedList?.length > 0 ? (
              <>
                {text}{" "}
                <span className="notificationCount">
                  ({checkedList?.length})
                </span>
              </>
            ) : (
              <>{text}</>
            )
          }
          size="large"
          className="filterBtn"
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
    </>
  );
};

export default Filter;
