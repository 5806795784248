import "./style.css";
import { ConfigProvider, Form, Select } from "antd";
import { SelectProps } from "../../../constants/interfaces";

const SelectInput = (props: SelectProps) => {
  const {
    placeholder,
    optionFilterProp,
    options,
    disabled,
    value,
    onChange,
    onSearch,
    defaultValue,
    filterOption,
    showSearch,
    allowClear,
    dropdownStyle,
    size,
    name,
    rules,
    label,
  } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            algorithm: true,
          },
        },
      }}
    >
      <Form.Item name={name} rules={rules} label={label}>
        <Select
          className="commonSelect"
          size={size}
          placeholder={placeholder}
          optionFilterProp={optionFilterProp}
          options={options}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onSearch={onSearch}
          defaultValue={defaultValue}
          filterOption={filterOption}
          showSearch={showSearch}
          allowClear={allowClear}
          dropdownStyle={dropdownStyle}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

export default SelectInput;
