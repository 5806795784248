import "./style.css";
import { Checkbox, ConfigProvider, Row } from "antd";
import { Tables } from "../../../common/table";
import { useEffect, useState } from "react";

const PermissionTable = (props: any) => {
  const { rowData, checkboxDisaled, handleCheckboxChange } = props;
  const [tableHeight, setTableHeight] = useState<number>();

  const calHeight = () => {
    const cardElement = document.getElementById("roleCard");
    const firstRow = document.getElementById("rolesFirstRow");
    const secRow = document.getElementById("rolesSecRow");
    if (cardElement && firstRow && secRow) {
      const firstRowHeight = firstRow?.clientHeight;
      const secRowHeight = secRow?.clientHeight;
      const cardHeight = cardElement.clientHeight;
      let availableHeight = cardHeight - (firstRowHeight + secRowHeight + 118);
      setTableHeight(availableHeight);
    }
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left" as const,
      width: "18.75%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left" as const,
      width: "56.82%",
    },
    {
      title: "Rights",
      dataIndex: "rights",
      key: "rights",
      align: "left" as const,
      width: "18.13%",
      render: (_: any, record: any) => (
        <Row>
          <Checkbox
            className="rolesFormCheckbox view"
            checked={record?.rights?.view}
            onChange={() => handleCheckboxChange(record?.key, "view")}
            disabled={checkboxDisaled}
          >
            View
          </Checkbox>
          <Checkbox
            className="rolesFormCheckbox"
            checked={record?.rights?.edit}
            onChange={() => handleCheckboxChange(record?.key, "edit")}
            disabled={checkboxDisaled}
          >
            Edit
          </Checkbox>
        </Row>
      ),
    },
  ];
  return (
    <>
      <Tables
        className="permissionsTable"
        columns={columns}
        data={rowData}
        pagination={false}
        scroll={{ y: tableHeight }}
      />
    </>
  );
};

export default PermissionTable;
