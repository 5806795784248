import { Col, Form, Row } from "antd";
import "./style.css";
import { useForm } from "antd/es/form/Form";
import CommonInput from "../../../common/input";
import SearchField from "../../../common/searchField";
import PermissionTable from "../permissionTable";
import { useState } from "react";

const RolesForm = (props: any) => {
  const { onSubmit, formData, setFormData, onFormChange, formRef } = props;
  const { role, description, tableData } = formData || {};
  const [form] = useForm();
  if (formRef) {
    formRef.current = form;
  }
  const [search, setSearch] = useState("");
  const handleChange = (changedValues: any) => {
    onFormChange(changedValues);
  };

  const handleCheckboxChange = (key: string, type: "view" | "edit") => {
    setFormData((prevData: any) => {
      const updatedTableData = prevData?.tableData?.map((item: any) => {
        if (item?.key === key) {
          return {
            ...item,
            rights: { ...item?.rights, [type]: !item?.rights[type] },
          };
        }
        return item;
      });
      return { ...prevData, tableData: updatedTableData };
    });
  };

  const getFilteredTableData = () => {
    if (!search) return tableData;

    return tableData.filter(
      (item: any) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="rolesForm"
      layout="vertical"
      onValuesChange={(changedValue) => handleChange(changedValue)}
      id="rolesForm"
    >
      <Row className="roleFormFirstRow" id="rolesFirstRow">
        <Col xs={24} sm={12} md={10} lg={8} xl={6} className="roleFormLeftCol">
          <CommonInput
            name="roleName"
            label="Role Name"
            type="text"
            value={role}
            placeholder="Enter role name"
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          md={14}
          lg={16}
          xl={18}
          className="roleFormRightCol"
        >
          <CommonInput
            name="description"
            label="Description"
            type="text"
            value={description}
            placeholder="Enter description"
          />
        </Col>
      </Row>
      <Row className="roleFormSecRow" id="rolesSecRow">
        <Col
          xs={24}
          sm={12}
          md={13}
          lg={15}
          xl={17}
          className="roleFormTextCol"
        >
          <span className="roleFormHeadText">Permissions</span>
          <span className="roleFormSubHeadText">
            Lorem ipsum dolor sit amet consectetur. Velit libero enim
            consectetur aliquam.
          </span>
        </Col>
        <Col xs={24} sm={12} md={11} lg={9} xl={7}>
          <SearchField
            placeholder="Search Permissions"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
          />
        </Col>
      </Row>
      <Row className="roleFromTableRow">
        <PermissionTable
          rowData={getFilteredTableData()}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Row>
    </Form>
  );
};

export default RolesForm;
