import { Avatar, Col, Dropdown, Layout, MenuProps, Row } from "antd";
import "./style.css";
import GoogleIcons from "../googleIcon";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../../routes/config";
import { setLoginState } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const headingText = "Neev";
  const photo = "http://";
  const name = "Yogesh";
  const email = "yogesh.kumar@innobitsystems.com";
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(setLoginState(false));
    navigate(PageRoutes.Login);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Profile",
      icon: <GoogleIcons iconName="person" className="dropdownIcons" />,
    },
    {
      key: "2",
      label: "Help",
      icon: <GoogleIcons iconName="contact_support" className="dropdownIcons" />,
    },
    {
      key: "4",
      label: "Logout",
      icon: <GoogleIcons iconName="logout" className="dropdownIcons" />,
      onClick: handleLogout,
    },
  ];

  return (
    <Layout.Header className="header" id="header">
      <Row className="headerContentRow">
        <span className="headingSpan">{headingText}</span>
        <Row>
          <Avatar src={photo} />
          <Col className="headerProfileCol">
            <Col className="headerProfileNameCol">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                overlayClassName="profileDropdown"
              >
                <a
                  className="dropdownTrigger"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="headerProfileName">{name}</span>
                  <GoogleIcons iconName="keyboard_arrow_down" />
                </a>
              </Dropdown>
            </Col>
            <span className="headerProfileEmail">{email}</span>
          </Col>
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default Header;
