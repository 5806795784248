import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PageRoutes } from "../config";

export const ProtectedRoute = (props: any) => {
  const { isLoggedIn } = props;
  let location = useLocation();

  return !isLoggedIn ? (
    <Navigate to={PageRoutes.Login} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export const PublicRoute = (props: any) => {
  const { isLoggedIn } = props;
  return isLoggedIn ? <Navigate to="/" replace /> : <Outlet />;
};
