import "./style.css";
import { ConfigProvider, Dropdown, Form, Input } from "antd";
import { InputProps } from "../../../constants/interfaces";
import { useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const CommonInput = (props: InputProps) => {
  const {
    className,
    type = "text",
    value,
    onChange,
    prefix,
    placeholder,
    disabled,
    suffix,
    name,
    rules,
    label,
    createPassword,
    addonAfter,
    addonBefore,
    status,
    validateStatus,
    help,
  } = props;
  const InputComponent = type === "password" ? Input.Password : Input;
  const [passwordStrength, setPasswordStrength] = useState("Week");
  const [validationVisible, setValidationVisible] = useState(false);

  const requirements = [
    { key: "1", label: "8 Characters", regex: /.{8,}/ },
    { key: "2", label: "One lower case character", regex: /[a-z]/ },
    { key: "3", label: "One Capital letter", regex: /[A-Z]/ },
    { key: "4", label: "One number", regex: /[0-9]/ },
    { key: "5", label: "One special character", regex: /[^A-Za-z0-9]/ },
  ];

  const getIcon = (regex: any) =>
    regex.test(value) ? (
      <CheckCircleOutlined className="checkIcon" />
    ) : (
      <CloseCircleOutlined className="closeIcon" />
    );

  const items = [
    {
      key: "title",
      label: <strong>Password must have at least</strong>,
    },
    ...requirements.map((req) => ({
      key: req.key,
      label: req.label,
      icon: getIcon(req.regex),
    })),
  ];

  const handleValidation = () => {
    setValidationVisible(!validationVisible);
  };

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    onChange(e);
    setPasswordStrength(calculatePasswordStrength(password));
  };

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;

    switch (strength) {
      case 5:
        return "Strong";
      case 4:
        return "Good";
      case 3:
        return "Medium";
      default:
        return "Weak";
    }
  };
  const getStrengthColor = () => {
    switch (passwordStrength) {
      case "Strong":
        return "#27CD41";
      case "Good":
        return "#1890ff";
      case "Medium":
        return "#faad14";
      case "Weak":
      default:
        return "#f5222d";
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            borderRadius: 4,
            lineHeight: 2.715,
            algorithm: true,
          },
        },
      }}
    >
      <Form.Item
        className="commonFormInput"
        name={name}
        rules={rules}
        label={label}
        validateStatus={validateStatus}
        help={help}
      >
        <>
          <InputComponent
            className={`commonInput ${className}`}
            name={name}
            type={type}
            value={value}
            onChange={type === "password" ? handlePasswordChange : onChange}
            prefix={prefix}
            suffix={suffix}
            placeholder={placeholder}
            disabled={disabled}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            status={status}
          />
          {createPassword && type === "password" && (
            <div className="validationDiv">
              <span>
                Password strength:{" "}
                <span style={{ color: getStrengthColor() }}>
                  {value?.length > 0 ? passwordStrength : ""}
                </span>
              </span>
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                trigger={["click"]}
                open={validationVisible}
                arrow
              >
                <span onClick={handleValidation} className="validationSpan">
                  {validationVisible ? "Hide validations" : "Show validations"}
                </span>
              </Dropdown>
            </div>
          )}
        </>
      </Form.Item>
    </ConfigProvider>
  );
};

export default CommonInput;
