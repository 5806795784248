import {
  Card,
  Col,
  Dropdown,
  Menu,
  MenuProps,
  Pagination,
  Row,
  Switch,
  Tooltip,
} from "antd";
import "./style.css";
import SearchField from "../../../common/searchField";
import CustomeButton from "../../../common/button";
import GoogleIcons from "../../../common/googleIcon";
import { Tables } from "../../../common/table";
import Filter from "../../../common/filter";
import { Link, useOutletContext } from "react-router-dom";
import AddUser from "../addUserModal";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../../common/confirmModal";
import { PageRoutes } from "../../../../routes/config";

const ManageUser = () => {
  const { setBreadcrumbItems }: any = useOutletContext();
  useEffect(() => {
    setBreadcrumbItems([
      { title: "Home" },
      { title: "Manage Users" },
    ]);
  }, [setBreadcrumbItems]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableHeight, setTableHeight] = useState<number>();
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [userTypeFilter, setUserTypeFilter] = useState<string[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const handleEdit = (user: any) => {
    setCurrentUser(user);
    setEditOpen(true);
  };
  const statusItems: any = [
    {
      key: "1",
      label: "Invited",
      value: "invited",
    },
    {
      key: "2",
      label: "Active",
      value: "active",
    },
    {
      key: "3",
      label: "Inactive",
      value: "inactive",
    },
  ];

  const userItems: any = [
    {
      key: "1",
      label: "Platform Super Admin",
      value: "Platform Super Admin",
    },
    {
      key: "2",
      label: "Platform System Admin",
      value: "Platform System Admin",
    },
    {
      key: "3",
      label: "Platform Support Staff",
      value: "Platform Support Staff",
    },
  ];

  const [rowData, setRowData] = useState([
    {
      key: "1",
      imageURL: null,
      name: "Mr Ramcharan Singh",
      email: "Neeta.Tiwari@gmail.com",
      mobileNumber: "+91 8938746474",
      userType: "Platform Super Admin",
      lastLogin: "23-Jun-2013",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "No",
    },
    {
      key: "2",
      imageURL: null,
      name: "Miss Kajal Pathak",
      email: "dinesh.gangwani@hyperdash.com",
      mobileNumber: "+91 9188376472",
      userType: "Platform System Admin",
      lastLogin: "23-Jun-2013",
      status: "inactive",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "No",
    },
    {
      key: "3",
      imageURL: null,
      name: "Mr Rakesh Naidu",
      email: "rakesh.naidu12@hyperdash.com",
      mobileNumber: "+91 8938746474",
      userType: "Platform Support Staff",
      lastLogin: "23-Jun-2013",
      status: "invited",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "4",
      imageURL: null,
      name: "Mr Vinay Kumar",
      email: "Neeta.Tiwari@gmail.com",
      mobileNumber: "+91 9188376472",
      userType: "Platform Support Staff",
      lastLogin: "23-Jun-2013",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "No",
    },
    {
      key: "5",
      imageURL: null,
      name: "Mr Dinesh Gangwani",
      email: "dinesh.gangwani@hyperdash.com",
      mobileNumber: "+91 9100298376",
      userType: "Platform Support Staff",
      lastLogin: "23-Jun-2013",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "E-mail",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "6",
      imageURL: null,
      name: "Ms Alka Mishra",
      email: "alka.mishra@domain.com",
      mobileNumber: "+91 8123456789",
      userType: "Platform Manager",
      lastLogin: "15-Apr-2022",
      status: "active",
      communicationMethod: "SMS",
      onBoardingMode: "Manual",
      allowMultipleSessions: "No",
      twoFactorAuthentication: "No",
    },
    {
      key: "7",
      imageURL: null,
      name: "Mr Sahil Verma",
      email: "sahil.verma@domain.com",
      mobileNumber: "+91 9876543210",
      userType: "Platform Support Staff",
      lastLogin: "12-May-2023",
      status: "invited",
      communicationMethod: "E-mail",
      onBoardingMode: "E-mail",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "8",
      imageURL: null,
      name: "Mrs Priya Sharma",
      email: "priya.sharma@domain.com",
      mobileNumber: "+91 9012345678",
      userType: "Platform User",
      lastLogin: "11-Feb-2022",
      status: "active",
      communicationMethod: "SMS",
      onBoardingMode: "Manual",
      allowMultipleSessions: "No",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "9",
      imageURL: null,
      name: "Mr Amit Jain",
      email: "amit.jain@domain.com",
      mobileNumber: "+91 9988776655",
      userType: "Platform Manager",
      lastLogin: "08-Jan-2023",
      status: "inactive",
      communicationMethod: "Phone Call",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "No",
    },
    {
      key: "10",
      imageURL: null,
      name: "Ms Neha Gupta",
      email: "neha.gupta@domain.com",
      mobileNumber: "+91 9022334455",
      userType: "Platform Support Staff",
      lastLogin: "27-Mar-2022",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "11",
      imageURL: null,
      name: "Dr Sameer Desai",
      email: "sameer.desai@domain.com",
      mobileNumber: "+91 9874563210",
      userType: "Platform Support Staff",
      lastLogin: "01-Dec-2021",
      status: "active",
      communicationMethod: "Phone Call",
      onBoardingMode: "Manual",
      allowMultipleSessions: "No",
      twoFactorAuthentication: "No",
    },
    {
      key: "12",
      imageURL: null,
      name: "Ms Anjali Bhatt",
      email: "anjali.bhatt@domain.com",
      mobileNumber: "+91 9988112233",
      userType: "Platform Support Staff",
      lastLogin: "09-Oct-2022",
      status: "invited",
      communicationMethod: "E-mail",
      onBoardingMode: "E-mail",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "13",
      imageURL: null,
      name: "Mr Vikas Khanna",
      email: "vikas.khanna@domain.com",
      mobileNumber: "+91 9876123450",
      userType: "Platform User",
      lastLogin: "21-Jun-2023",
      status: "active",
      communicationMethod: "SMS",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "14",
      imageURL: null,
      name: "Mrs Sheetal Mehta",
      email: "sheetal.mehta@domain.com",
      mobileNumber: "+91 9090909090",
      userType: "Platform Manager",
      lastLogin: "19-Nov-2022",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "No",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "15",
      imageURL: null,
      name: "Mr Rajeev Kapoor",
      email: "rajeev.kapoor@domain.com",
      mobileNumber: "+91 9123456789",
      userType: "Platform System Admin",
      lastLogin: "02-Apr-2022",
      status: "inactive",
      communicationMethod: "Phone Call",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "No",
    },
    {
      key: "16",
      imageURL: null,
      name: "Ms Pooja Rana",
      email: "pooja.rana@domain.com",
      mobileNumber: "+91 9878876543",
      userType: "Platform User",
      lastLogin: "05-Jul-2023",
      status: "active",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
    {
      key: "17",
      imageURL: null,
      name: "Mr Tarun Ahuja",
      email: "tarun.ahuja@domain.com",
      mobileNumber: "+91 9998877665",
      userType: "Platform Support Staff",
      lastLogin: "17-Feb-2022",
      status: "inactive",
      communicationMethod: "E-mail",
      onBoardingMode: "Manual",
      allowMultipleSessions: "No",
      twoFactorAuthentication: "No",
    },
    {
      key: "18",
      imageURL: null,
      name: "Ms Kavita Rao",
      email: "kavita.rao@domain.com",
      mobileNumber: "+91 8888555500",
      userType: "Platform Support Staff",
      lastLogin: "23-Aug-2023",
      status: "invited",
      communicationMethod: "SMS",
      onBoardingMode: "E-mail",
      allowMultipleSessions: "Yes",
      twoFactorAuthentication: "Yes",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const filteredData = rowData.filter((item) => {
    const search =
      nameFilter === "" ||
      item.email.toLowerCase().includes(nameFilter.toLowerCase()) ||
      item.name.toLowerCase().includes(nameFilter.toLowerCase());
    const matchesStatus =
      statusFilter.length === 0 ||
      statusFilter.includes(item.status.toLowerCase());
    const matchesUserType =
      userTypeFilter.length === 0 || userTypeFilter.includes(item.userType);
    return matchesStatus && matchesUserType && search;
  });

  const calHeight = () => {
    const headerElement = document.getElementById("header");
    const cardHeight = document.getElementById("userCard");
    if (headerElement && cardHeight) {
      const headbarHeight = headerElement.clientHeight;
      const cardHeights = cardHeight.clientHeight;
      let availableHeight = cardHeights * 0.7;
      setTableHeight(availableHeight);
    }
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
  }, []);

  // Slice data to show only the current page data
  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left" as const,
      key: "name",
      width: "16%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => (
        <span style={{ color: "#006CD0" }}>{text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left" as const,
      key: "email",
      width: "22%",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      align: "left" as const,
      width: "15%",
      key: "mobileNumber",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      align: "left" as const,
      width: "18%",
      key: "userType",
      sorter: (a: any, b: any) => a.userType.localeCompare(b.userType),
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      align: "left" as const,
      width: "10%",
      key: "lastLogin",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center" as const,

      width: "12%",
      key: "status",
      render: (text: any, record: any) => (
        <>
          {record.status == "invited" ? (
            <span className="invited">
              <span className="invitedText"> Invited </span>
              <GoogleIcons iconName="schedule" className="invitedIcon" />
            </span>
          ) : (
            <>
              {record.status == "active" ? "Active" : "Inactive"}

              <Switch
                checked={record.status == "active"}
                className="statusSwitch"
                size="small"
                // onChange={(checked) => handleSwitchChange(record.key, checked)}
              />
            </>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      align: "center" as const,
      render: (text: any, record: any) => {
        const items = [
          {
            key: "1",
            label: (
              <div className="actionItems" onClick={() => handleEdit(record)}>
                <GoogleIcons iconName="edit" className="actionItemIcon" />
                <span>Edit Details </span>
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div className="actionItems" onClick={() => setResetOpen(true)}>
                <GoogleIcons iconName="lock_open" className="actionItemIcon" />
                <span>Reset Password</span>
              </div>
            ),
          },
          {
            key: "3",
            label: (
              <div className="actionItems" onClick={() => setDeleteOpen(true)}>
                <GoogleIcons iconName="delete" className="actionItemIcon" />
                <span>Delete</span>
              </div>
            ),
          },
        ];
        return (
          <Dropdown
            overlayClassName="actionDropdown"
            menu={{ items }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            trigger={["click"]}
          >
            <GoogleIcons iconName="more_horiz" className="actionIcon" />
          </Dropdown>
        );
      },
    },
  ];

  const handleRefetch = (newUserData: any) => {
    const name = `${newUserData?.title} ${newUserData?.firstName} ${newUserData?.middleName} ${newUserData?.lastName}`;
    const mobileNumber = `${newUserData?.numCode} ${newUserData?.mobileNumber}`;
    const updatedUser = {
      key: newUserData?.key || `${rowData?.length + 1}`, // Generate a new key if not editing
      name: name,
      imageURL: newUserData?.imageURL,
      email: newUserData?.email,
      mobileNumber: mobileNumber,
      userType: newUserData?.userType,
      lastLogin: "23-Jun-2013",
      status: "active",
      communicationMethod: newUserData?.communicationMethod,
      onBoardingMode: newUserData?.onBoardingMode,
      allowMultipleSessions: newUserData?.allowMultipleSessions,
      twoFactorAuthentication: newUserData?.twoFactorAuthentication,
    };

    if (newUserData.key) {
      // Editing existing user
      const updatedRowData = rowData?.map((user) =>
        user?.key === newUserData?.key ? updatedUser : user
      );
      setRowData(updatedRowData);
      setEditOpen(false);
    } else {
      // Adding new user
      setRowData([updatedUser, ...rowData]);
      setOpen(false);
    }
  };

  return (
    <>
      <Card className="userCard" id="userCard">
        <Row gutter={[16, 24]}>
          <Col span={7}>
            <SearchField
              onChange={(e: any) => setNameFilter(e.target.value)}
              placeholder={"Search name or email"}
            />
          </Col>
          <Col span={4}>
            <Filter
              text={"User Type"}
              items={userItems}
              name={"userType"}
              data={rowData}
              checkedList={userTypeFilter}
              setCheckedList={setUserTypeFilter}
            />
          </Col>
          <Col span={4}>
            <Filter
              text={"Status"}
              items={statusItems}
              name={"status"}
              data={rowData}
              checkedList={statusFilter}
              setCheckedList={setStatusFilter}
            />
          </Col>
          <Col span={5} className="createUserBtn">
            <CustomeButton
              type="primary"
              text="Create New User"
              size="large"
              icon={<GoogleIcons iconName="add" />}
              iconPosition="end"
              className="userbtn"
              onClick={() => setOpen(true)}
            />
          </Col>
          <AddUser
            open={open}
            setOpen={setOpen}
            isEdit={false}
            handleRefetch={handleRefetch}
          />
          <AddUser
            open={editOpen}
            setOpen={setEditOpen}
            handleRefetch={handleRefetch}
            isEdit={true}
            data={currentUser}
          />

          <Col xs={24} className="userTable">
            <Tables
              className="table"
              columns={columns}
              data={paginatedData}
              scroll={{ y: tableHeight }}
              showSorterTooltip={false}
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
      <Pagination
        total={rowData.length}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        align="end"
        showSizeChanger
        pageSizeOptions={["5", "10", "15"]}
        onShowSizeChange={handlePageSizeChange}
        locale={{
          items_per_page: "",
        }}
        className="userPagination"
        pageSize={pageSize}
        defaultPageSize={10}
        onChange={handlePageChange}
      />
      <ConfirmationModal
        open={deleteOpen}
        title={"Delete User !"}
        setOpen={setDeleteOpen}
        cancelText="No"
        okText="Yes"
        message="You are going to delete this user Are you sure"
        okButtonDanger={true}
      />
      <ConfirmationModal
        open={resetOpen}
        title={"Request Sent"}
        setOpen={setResetOpen}
        okText="Ok"
        message="An email has been sent to the user to change his password"
        okButtonDanger={false}
      />
    </>
  );
};

export default ManageUser;
