import { Button, ConfigProvider, Spin } from "antd";
import "./style.css";
import { Buttonprops } from "../../../constants/interfaces";
import { LoadingOutlined } from "@ant-design/icons";

const CustomeButton = (props: Buttonprops) => {
  const {
    className,
    type,
    htmlType,
    size,
    loading,
    disabled,
    onClick,
    text,
    icon,
    iconPosition,
    danger,
    style,
  } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#006CD0",
            colorPrimaryBgHover: "#035096",
            colorBorder: "#006CD0",
            colorText: "#006CD0",
            colorPrimaryHover: "#035096",
            colorBgContainerDisabled: "#DEDEE1",
            borderRadius: 4,
            borderColorDisabled: "none",
            colorTextDisabled: "white",
            algorithm: true,
          },
        },
      }}
    >
      <Button
        className={`button ${className} ${type} ${disabled ? "disabled" : ""} ${danger ? "danger" : ""}`}
        type={type}
        htmlType={htmlType}
        size={size}
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        icon={icon}
        iconPosition={iconPosition}
        danger={danger}
        style={style}
      >
        {text}
      </Button>
    </ConfigProvider>
  );
};
export default CustomeButton;
