import "./style.css";
import { Col, Form, Row, Typography } from "antd";
import CustomCarousel from "../../../common/carousell";
import CommonInput from "../../../common/input";
import CustomeButton from "../../../common/button";
import { useState } from "react";
import GoogleIcons from "../../../common/googleIcon";
import { useNavigate } from "react-router-dom";
import { RegExpression } from "../../../../constants/regExp";
import { Logo } from "../../../../assets/icons";

const { useForm } = Form;

export const ChangePassword = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    console.log("onSubmit");
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleBack = () => {
    navigate("/login");
  };

  return (
    <Row className="loginMainRow">
      <Col xl={12} className="loginImageCol">
        <CustomCarousel />
      </Col>
      <Col xs={24} sm={22} md={20} lg={18} xl={12} className="loginFormCol">
        <Row className="loginFormRow">
          <Col
            xs={22}
            sm={18}
            md={16}
            lg={16}
            xl={16}
            xxl={14}
            className="changePassHeadingCol"
          >
            <img alt="Logo" className="logoImage" src={Logo} />
            <Col>
              <div className="changePassBackButtonCol" onClick={handleBack}>
                <GoogleIcons
                  iconName="arrow_left_alt"
                  className="changePassBackIcon"
                />
                <span className="changePassBackText">Sing in</span>
              </div>
            </Col>
          </Col>
          <Col xs={22} sm={18} md={16} lg={16} xl={16} xxl={14}>
            <Typography.Title level={1} className="loginHeadingText">
              Change Password
            </Typography.Title>
            <Typography.Text className="loginSubHeadingText">
              Create a strong password
            </Typography.Text>

            <Form
              form={form}
              onFinish={onSubmit}
              className="loginFormForm"
              layout="vertical"
            >
              <CommonInput
                label="New Password"
                name="newPassword"
                type="password"
                value={formData.newPassword}
                onChange={handleChange}
                prefix={
                  <GoogleIcons iconName="lock_open" className="prefixIcon" />
                }
                placeholder="Enter new Password"
                status={
                  !RegExpression?.Password.test(formData.newPassword) &&
                  formData.newPassword !== ""
                    ? "error"
                    : ""
                }
                createPassword
                disabled={loading}
              />
              <CommonInput
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
                prefix={<GoogleIcons iconName="lock" className="prefixIcon" />}
                placeholder="Enter Password"
                disabled={
                  !RegExpression?.Password.test(formData.newPassword) || loading
                }
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (!value || formData.newPassword === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Passwords don't match"));
                    },
                  },
                ]}
              />
              <Row justify="space-between">
                <CustomeButton
                  type="primary"
                  text="Change Password"
                  htmlType="submit"
                  size="large"
                  iconPosition="end"
                  disabled={
                    !formData.newPassword ||
                    formData.newPassword !== formData.confirmPassword
                  }
                  className="changePasswordBtn"
                  loading={loading}
                />
              </Row>
            </Form>
          </Col>
          <Col span={24} className="termConditionCol">
            <span className="termConditionSpan">Terms & Conditions</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
