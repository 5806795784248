import { Col, Form, Row, Typography } from "antd";
import { Logo } from "../../../../assets/images";
import { RegExpression } from "../../../../constants/regExp";
import CustomeButton from "../../../common/button";
import CustomCarousel from "../../../common/carousell";
import GoogleIcons from "../../../common/googleIcon";
import CommonInput from "../../../common/input";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email);
  const onSubmit = () => {
    console.log("onSubmit");
  };
  const handleSignin = () => {
    console.log("signin button Clicked");
    navigate(-1);
  };
  useEffect(() => {
    form.setFieldsValue({
      email: location?.state?.email,
    });
  }, [location?.state?.email]);

  return (
    <>
      <>
        <Row className="loginMainRow">
          <Col xl={12} className="loginImageCol">
            <CustomCarousel />
          </Col>
          <Col xs={24} sm={22} md={20} lg={18} xl={12} className="loginFormCol">
            <Row className="loginFormRow">
              <Col
                xs={22}
                sm={18}
                md={16}
                lg={14}
                xl={16}
                className="forgotPassHeading"
              >
                <img alt="Logo" src={Logo} className="logo" />

                <Col>
                  <div className="signinButton" onClick={handleSignin}>
                    <GoogleIcons iconName={"arrow_left_alt"} />
                    <span className="signinText">Sign in</span>
                  </div>
                </Col>
              </Col>

              <Col xs={22} sm={18} md={16} lg={14} xl={16}>
                <Typography.Title level={1} className="loginHeadingText">
                  Forgot Password
                </Typography.Title>
                <Typography.Text className="loginSubHeadingText">
                  A 6 digit unique code will sent to your email Id
                </Typography.Text>

                <Form
                  form={form}
                  onFinish={onSubmit}
                  className="loginFormForm"
                  layout="vertical"
                >
                  <CommonInput
                    name="email"
                    label="Email"
                    rules={[
                      {
                        pattern: RegExpression?.Email,
                        message: "Please enter a valid email address!",
                      },
                    ]}
                    type="text"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    prefix={
                      <GoogleIcons iconName="mail" className="prefixIcon" />
                    }
                    placeholder="Enter Email"
                  />

                  <Row justify="space-between">
                    <CustomeButton
                      type="primary"
                      text="Continue"
                      htmlType="submit"
                      size="large"
                      disabled={!email}
                      icon={<GoogleIcons iconName="arrow_right_alt" />}
                      iconPosition="end"
                      className="continueButton"
                    />
                  </Row>
                </Form>
              </Col>
              <Col span={24} className="termConditionCol">
                <span className="termConditionSpan">Terms & Conditions</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </>
  );
};

export default ForgotPassword;
