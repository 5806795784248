import "./style.css";
import { Avatar, Col, Modal, Row } from "antd";
import { ConfirmModalProps } from "../../../constants/interfaces";
import CustomeButton from "../button";
import { cation, check } from "../../../assets/icons";

const ConfirmationModal = (props: ConfirmModalProps) => {
  const {
    className,
    open,
    setOpen,
    title,
    message,
    onOk,
    okText = "OK",
    cancelText = "Cancel",
    okButtonDanger,
  } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      className={`modal ${className}`}
      open={open}
      onOk={onOk}
      closable={false}
      footer={false}
      width={299}
      centered
    >
      <Row className="confirmModalContentRow">
        <Row className="confirmModalTextCol">
          <Col className="confirmModalAvatarCol">
            {okButtonDanger ? (
              <Avatar
                className="confirmModalAvatarDanger"
                size={53}
                src={cation}
              />
            ) : (
              <Avatar
                className="confirmModalAvatarCheck"
                size={53}
                src={check}
              />
            )}
          </Col>
          <span className="confirmModalTitleSpan">{title}</span>
          <span className="confirmModalTextSpan">{message}</span>
        </Row>
        <Row className="confirmModalButtonRow">
       { okButtonDanger ? (
        <>
          <Col span={12} className="confirmModalBtnCol left">
            <CustomeButton
              className="confirmModalBtn"
              size="large"
              text={cancelText}
              onClick={handleCancel}
            />
          </Col>
          <Col span={12} className="confirmModalBtnCol right">
            <CustomeButton
              className="confirmModalBtn"
              text={okText}
              onClick={onOk}
              size="large"
              type="primary"
              danger={okButtonDanger}
            />
          </Col>
          </>
        ) : (
          <Col span={24} className="confirmModalBtnCol right">
            <CustomeButton
              className="confirmModalBtn"
              text={okText}
              onClick={() => handleCancel()}
              size="large"
              type="primary"
              danger={okButtonDanger}
            />
          </Col>
        )
      }
        </Row>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
