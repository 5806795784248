export enum PageRoutes {
  Dashboard = "/dashboard",
  Tenants = "/tenants",
  ManageUser = "/manage-user",
  Configurations = "/",
  Roles = "/roles",
  AddRole = "/roles/add",
  EditRole = "/roles/edit",
  ViewRole = "/roles/view",
  Notification = "/",
  Settings = "/",
  ChangePassword = "/change-password",
  Login = "/login",
  ForgotPassword = "/forgot-password",
}
