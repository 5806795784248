import "./style.css";
import { Col, Modal, Row } from "antd";
import CustomeButton from "../button";
import { CommonModalProps } from "../../../constants/interfaces";

const CommonModal = (props: CommonModalProps) => {
  const {
    title,
    open,
    handleClose,
    children,
    footer,
    primaryButtonText,
    secondaryButtonText,
    primaryClickHandler,
    secondaryClickHandler,
    primaryButtonIcon,
    secondaryButtonIcon,
    primaryButtonIconPosition,
    secondaryButtonIconPosition,
    primaryButtonLoading,
    secondaryButtonDisabled,
  } = props;

  return (
    <Modal
      className="commonModal"
      title={title}
      open={open}
      footer={
        footer ? (
          footer
        ) : (
          <Row className="footerButtonRow">
            <Col>
              <CustomeButton
                text={secondaryButtonText}
                icon={secondaryButtonIcon}
                iconPosition={secondaryButtonIconPosition}
                onClick={secondaryClickHandler}
                disabled={secondaryButtonDisabled}
              />
            </Col>
            <Col>
              <CustomeButton
                type="primary"
                text={primaryButtonText}
                icon={primaryButtonIcon}
                iconPosition={primaryButtonIconPosition}
                loading={primaryButtonLoading}
                onClick={primaryClickHandler}
              />
            </Col>
          </Row>
        )
      }
      onCancel={handleClose}
      centered
      width="64.93vw"
      maskClosable={false}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
