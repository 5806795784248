import "./style.css";
import { ConfigProvider, Form, Segmented } from "antd";
import { SegmentedButtonProps } from "../../../constants/interfaces";

const SegmentedButton = (props: SegmentedButtonProps) => {
  const {
    options,
    value,
    size,
    onChange,
    name,
    rules,
    label,
    defaultValue,
    disabled,
  } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            borderRadius: 4,
            algorithm: true,
          },
        },
      }}
    >
      <Form.Item name={name} rules={rules} label={label}>
        <Segmented
          className="segmentedBtn"
          name={name}
          options={options}
          value={value}
          size={size}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

export default SegmentedButton;
