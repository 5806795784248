export const COUNTRY_CODES = [
  { value: "+91", label: "+91" },
  { value: "+92", label: "+92" },
  { value: "+1", label: "+1" },
  { value: "+44", label: "+44" },
  { value: "+61", label: "+61" },
  { value: "+81", label: "+81" },
  { value: "+49", label: "+49" },
  { value: "+33", label: "+33" },
  { value: "+86", label: "+86" },
  { value: "+7", label: "+7" },
];
