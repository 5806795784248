import { useRef } from "react";
import { CloseIcon } from "../../../assets/icons";
import "./style.css";
import { Col } from "antd";
import { ImageInputProps } from "../../../constants/interfaces";

const ImageInput = (props: ImageInputProps) => {
  const {
    imageURL,
    onFormChange,
    heading,
    subHeading,
    UploadIconSrc,
    changeHeading,
  } = props;
  const inputPhotoRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    inputPhotoRef.current?.click();
  };

  const handleClear = () => {
    onFormChange({ imageURL: null });
    if (inputPhotoRef.current) {
      inputPhotoRef.current.value = "";
    }
  };

  const handleImageChange = (e: any) => {
    let file = e.target.files[0];
    if (!file) {
      onFormChange({ imageURL: null });
      return;
    } else {
      onFormChange({ imageURL: file });
    }
  };

  return (
    <>
      {imageURL !== null && imageURL !== undefined ? (
        <div className="imageContainer">
          <img
            src={URL?.createObjectURL(imageURL)}
            alt="Profile Photo"
            className="imageUploadImg"
          />
          <img className="closeIcon" src={CloseIcon} onClick={handleClear} />
        </div>
      ) : (
        <div className="imageContainer">
          <img
            src={UploadIconSrc}
            alt="Photo upload icon"
            className="imageUploadIcon"
            onClick={handleClick}
          />
        </div>
      )}
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={inputPhotoRef}
        onChange={handleImageChange}
        className="fileInput"
      />
      <Col className="imageUploadTextCol">
        {!imageURL ? (
          <span className="imageUploadTitle">{heading}</span>
        ) : (
          <span className="imageUploadTitle urlColor" onClick={handleClick}>
            {changeHeading}
          </span>
        )}
        <span className="imageUploadHelpingText">{subHeading}</span>
      </Col>
    </>
  );
};

export default ImageInput;
