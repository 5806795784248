import "./style.css";
import { Card, ConfigProvider, Input } from "antd";
import { SearchProps } from "../../../constants/interfaces";
import GoogleIcons from "../googleIcon";

const card = () => {
  return (
    <Card styles={{ body: { padding: "2px 8px" } }}>
      <p className="cardText">Ctrl + K</p>
    </Card>
  );
};

const SearchField = (props: SearchProps) => {
  const {
    className,
    value,
    onChange,
    placeholder,
    allowClear,
    disabled,
    showCtrlK,
  } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            borderRadius: 4,
            lineHeight: 2.7,
            algorithm: true,
          },
        },
      }}
    >
      <Input
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        allowClear={allowClear}
        prefix={<GoogleIcons iconName="search" className="searchIcon" />}
        suffix={showCtrlK ? card() : null}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};

export default SearchField;
