import "./style.css";
import { Col, Form, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import CommonInput from "../../../common/input";
import SegmentedButton from "../../../common/segmentedButton";
import { PhotoUploadIcon } from "../../../../assets/icons";
import ImageInput from "../../../common/imageInput";
import { COUNTRY_CODES } from "../../../../constants/constants";
import SelectInput from "../../../common/selectInput";
import { useEffect } from "react";
const { Option } = Select;

const UserForm = (props: any) => {
  const { onSubmit, formData, onFormChange, formRef } = props;
  const [form] = useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        imageUrl: formData.imageURL,
        title: formData.title,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        userType: formData.userType,
        email: formData.email,
        numCode: formData.numCode,
        mobileNumber: formData.mobileNumber,
        communicationMethod: formData.communicationMethod,
        onBoardingMode: formData.onBoardingMode,
        allowMultipleSessions: formData.allowMultipleSessions,
        twoFactorAuthentication: formData.twoFactorAuthentication,
      });
    }
  }, [formData, form]);

  if (formRef) {
    formRef.current = form;
  }

  const communicationMethodOptions = ["E-mail", "Mobile Number"];
  const onBoardingModeOptions = ["E-mail", "Manual"];
  const allowMultipleSessionsOptions = ["Yes", "No"];
  const twoFactorAuthenticationOptions = ["Yes", "No"];

  const handleChange = (changedValues: any) => {
    onFormChange(changedValues);
  };

  const selectBefore = (
    <Select
      defaultValue={formData?.numCode || "+91"}
      onChange={(value) => onFormChange({ numCode: value })}
    >
      {COUNTRY_CODES.map((code) => (
        <Option key={code.value} value={code.value}>
          {code.label}
        </Option>
      ))}
    </Select>
  );

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
  ];

  const userTypeOptions = [
    { value: "Platform Super Admin", label: "Platform Super Admin" },
    { value: "Platform System Admin", label: "Platform System Admin" },
    { value: "Platform Support Staff", label: "Platform Support Staff" },
  ];

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="userForm"
      layout="vertical"
      onValuesChange={(changedValue) => handleChange(changedValue)}
    >
      <Row className="userFormRow" gutter={[48,24]}>
        <Col className="imageInputCol" xs={24} xl={8}>
            <ImageInput
              imageURL={formData.imageURL}
              onFormChange={onFormChange}
              heading="Upload Profile Image"
              changeHeading="Change Profile Image"
              subHeading="Jpeg, jpg or png (max size 500KB)"
              UploadIconSrc={PhotoUploadIcon}
            />
        </Col>
        <Col className="userFormInputCol" md={24} xl={16}>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <SelectInput
                name="title"
                label="Title"
                value={form.getFieldValue("title")}
                options={titleOptions}
                placeholder="Select Title"
              />
            </Col>
            <Col xs={24} md={12}>
              <CommonInput
                name="firstName"
                value={form.getFieldValue("firstName")}
                type="text"
                label="First Name"
                placeholder="Enter First Name"
              />
            </Col>
            <Col xs={24} md={12}>
              <CommonInput
                name="middleName"
                value={form.getFieldValue("middleName")}
                type="text"
                label="Middle Name"
                placeholder="Enter Middle Name"
              />
            </Col>
            <Col xs={24} md={12}>
              <CommonInput
                name="lastName"
                value={form.getFieldValue("lastName")}
                type="text"
                label="Last Name"
                placeholder="Enter Last Name"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} xl={8}>
          <SelectInput
            name="userType"
            label="Platform User Type"
            value={form.getFieldValue("userType")}
            options={userTypeOptions}
            placeholder="Select user type"
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <CommonInput
            name="email"
            value={form.getFieldValue("email")}
            type="text"
            label="Email Address"
            placeholder="Enter Email Address"
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <CommonInput
            name="mobileNumber"
            value={form.getFieldValue("mobileNumber")}
            type="text"
            label="Mobile Number"
            placeholder="Enter mobile number"
            addonBefore={selectBefore}
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <SegmentedButton
            label="Communication Method"
            name="communicationMethod"
            value={form.getFieldValue("communicationMethod")}
            options={communicationMethodOptions}
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <SegmentedButton
            label="Onboarding Mode"
            name="onBoardingMode"
            value={form.getFieldValue("onBoardingMode")}
            options={onBoardingModeOptions}
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <SegmentedButton
            label="Allow Multiple Sessions"
            name="allowMultipleSessions"
            value={form.getFieldValue("allowMultipleSessions")}
            options={allowMultipleSessionsOptions}
          />
        </Col>
        <Col xs={24} md={12} xl={8}>
          <SegmentedButton
            label="Two Factor Authentication Login"
            name="twoFactorAuthentication"
            value={form.getFieldValue("twoFactorAuthentication")}
            options={twoFactorAuthenticationOptions}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
