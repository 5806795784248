import { Card, Row } from "antd";
import "./style.css";
import { useEffect, useRef, useState } from "react";
import RolesForm from "../../../stateless/rolesAndPermissions/form";
import FormFooter from "../../../common/formFooter";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { PageRoutes } from "../../../../routes/config";

const AddRole = () => {
  const { setBreadcrumbItems }: any = useOutletContext();
  useEffect(() => {
    setBreadcrumbItems([
      { title: "Home" },
      { title: <Link to={PageRoutes.Roles}>Role</Link> },
      { title: "Add" },
    ]);
  }, [setBreadcrumbItems]);
  const formRef = useRef<any>(null);
  const navigate = useNavigate();
  const initialFormData = {
    role: "",
    description: "",
    tableData: [
      {
        key: "1",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: false, edit: true },
      },
      {
        key: "2",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: true, edit: false },
      },
      {
        key: "3",
        name: "Lorem ipsum dolor",
        description:
          "Lorem ipsum dolor sit amet consectetur. Velit libero enim consectetur aliquam. Commodo eget quam tincidunt imperdiet ullamcorper fermentum ultricies.",
        rights: { view: true, edit: false },
      },
    ],
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleFormChange = (changedValues: any) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const handleRefresh = () => {
    setFormData(initialFormData);
    if (formRef?.current) {
      formRef?.current.resetFields();
    }
  };

  const handleSubmit = () => {
    console.log("Form Data Submitted:", formData);
  };

  const isFormDataChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Row className="addRolesMainRow">
        <Card bordered={false} className="addRolesCard" id="roleCard">
          <RolesForm
            formData={formData}
            setFormData={setFormData}
            formRef={formRef}
            onFormChange={handleFormChange}
            onSubmit={handleSubmit}
          />
        </Card>
      </Row>
      <FormFooter
        submitButtonText="Create Role"
        onReset={handleRefresh}
        onCancel={handleBack}
        onSubmit={handleSubmit}
        resetDisabled={!isFormDataChanged()}
      />
    </>
  );
};

export default AddRole;
