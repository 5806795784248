import { Table } from "antd";
import { Tableprops } from "../../../constants/interfaces";
import "./style.css";

export const Tables = (props: Tableprops) => {
  const {
    data,
    columns,
    className,
    loading,
    pagination,
    rowSelection,
    scroll,
    title,
    size,
    expandable,
    footer,
    showSorterTooltip,
  } = props;

  return (
    <Table
      id="table"
      dataSource={data}
      columns={columns}
      className={className}
      loading={loading}
      pagination={pagination}
      rowSelection={rowSelection}
      scroll={scroll}
      title={title}
      size={size}
      expandable={expandable}
      footer={footer}
      showSorterTooltip={showSorterTooltip}
    />
  );
};
